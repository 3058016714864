// + de paramètres possible sur https://swiperjs.com/demos et sur https://swiperjs.com/swiper-api
var swiper1 = new Swiper(".diaporama", {
    /*grabCursor: true,
    effect: "creative",*/
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    speed: 2500,
    loop: true,
    parallax: true,
    /*creativeEffect: {
        prev: {
            shadow: true,
            translate: ["-20%", 0, -1],
        },
        next: {
            translate: ["100%", 0, 0],
        },
    },*/
    pagination: {
        el: ".diaporama-pagination",
        bulletClass: 'swiper-pagination-bullet diaporama-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active diaporama-bullet--active',
        clickable: true
    },
});

/*var swiper2 = new Swiper(".widgetCatalogue-swiper", {
    spaceBetween: 20,
    slidesPerView: 1,
    pagination: {
        el: ".widgetActualite-pagination",
        bulletClass: 'swiper-pagination-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active',
        clickable: true
    },
    navigation: {
        nextEl: ".widgetCatalogue-buttonNext",
        prevEl: ".widgetCatalogue-buttonPrev",
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});*/

var swiper3 = new Swiper(".widgetActualite-swiper", {
    spaceBetween: 20,
    slidesPerView: 1,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    pagination: {
        el: ".widgetActualite-pagination",
        bulletClass: 'swiper-pagination-bullet widgetActualite-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active widgetActualite-bullet--active',
        clickable: true
    },
    navigation: {
        nextEl: ".widgetActualite-button-next",
        prevEl: ".widgetActualite-button-prev",
    },
    breakpoints: {
        576: {
            slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 100,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 210,
        }
    }
});

/*var swiper4 = new Swiper(".ficheProduit-swiper", {
    spaceBetween: 15,
    slidesPerView: 3,
    navigation: {
        nextEl: ".ficheProduit-buttonNext",
        prevEl: ".ficheProduit-buttonPrev",
    },
    pagination: {
        el: ".widgetActualite-pagination",
        bulletClass: 'swiper-pagination-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active',
        clickable: true
    },
    breakpoints: {
        370: {
            slidesPerView: 4,
        },
        768: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        }
    }
});

var swiper5 = new Swiper('#galerie-slider', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.widgetGalerie-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.widgetGalerie-button-next',
        prevEl: '.widgetGalerie-button-prev',
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});

var swiper6 = new Swiper('#recrutement-slider', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        1920: {
          slidesPerView: 3,
          spaceBetween: 50,
        }
    },
});*/

var swiper7 = new Swiper('.widgetAuto-swiper', {
    spaceBetween: 30,
    slidesPerView: 1,
    pagination: {
        el: '.widgetAuto-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.widgetAuto-button-next',
        prevEl: '.widgetAuto-button-prev',
    },
    breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 76,
        },
    },
});

var swiper8 = new Swiper('.widgetAutoVIP-swiper', {
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.widgetAuto-button-next',
        prevEl: '.widgetAuto-button-prev',
    },
});


/*var swiper9 = new Swiper(".widgetWebimmo-swiper", {
  slidesPerView: 1,
  spaceBetween: 30,
  watchSlidesProgress: true,
  slideVisibleClass: 'widgetWebimmo-slide--visible',

  pagination: {
      el: ".widgetWebimmo-pagination",
      bulletClass: 'swiper-pagination-bullet widgetWebimmo-bullet',
      bulletActiveClass: 'swiper-pagination-bullet-active widgetWebimmo-bullet--active'
  },
  breakpoints: {
      768: {
          slidesPerView: 2,
      },
      992: {
          slidesPerView: 3,
      }
  }
});

var swiper10 = new Swiper(".webAutoFiche-swiper", {
    spaceBetween: 30,
    slidesPerView: 3,
    watchSlidesProgress: true,
    slideVisibleClass: 'webAutoFiche-slide--visible',

    pagination: {
        el: ".webAutoFiche-pagination",
        bulletClass: 'swiper-pagination-bullet webAutoFiche-paginationBullet',
        bulletActiveClass: 'swiper-pagination-bullet-active webAutoFiche-paginationBullet--active',
        clickable: true
    },
    breakpoints: {
        280: {
            slidesPerView: 4,
        },
        576: {
            slidesPerView: 5,
        },
        992: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        }
    }
});*/